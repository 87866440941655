<template>
    <div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("leaveRule.employeeType")
                }}</label>
                <Select
                    v-model.number="model.emp_type_id"
                    :class="{
                        'ivu-form-item-error': errors.has('emp_type_id')
                    }"
                >
                    <Option
                        v-for="(employeeType, index) in employeeTypes"
                        :key="index"
                        :value="employeeType.emp_type_id"
                        >{{ employeeType.emp_type_en }}
                    </Option>
                </Select>

                <div
                    class="ivu-form-item-error tw-text-red-600"
                    v-if="errors.has('emp_type_id')"
                >
                    {{ errors.first("emp_type_id") }}
                </div>
            </div>
        </div>
        <div class="col-md-12 tw-flex mb-3">
            <div class="col-md-6 tw-pr-2">
                <label class="form-col-label control-label required">{{
                    $t("leaveRule.leaveType")
                }}</label>
                <Select
                    v-model.number="model.leave_type_id"
                    :class="{
                        'ivu-form-item-error': errors.has('leave_type_id')
                    }"
                >
                    <Option
                        v-for="(leaveType, index) in leaveTypes"
                        :key="index"
                        :value="leaveType.leave_type_id"
                        >{{ leaveType.leave_type_en }}
                    </Option>
                </Select>
                <div
                    class="ivu-form-item-error tw-text-red-600"
                    v-if="errors.has('leave_type_id')"
                >
                    {{ errors.first("leave_type_id") }}
                </div>
            </div>
            <div class="col-md-6 tw-pl-2">
                <label class="form-col-label control-label required">{{
                    $t("leaveRule.entitlement")
                }}</label>
                <input
                    v-model.trim.number="model.total_day_allow"
                    type="number"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('total_day_allow') }"
                />
                <div
                    class="ivu-form-item-error tw-text-red-600"
                    v-if="errors.has('total_day_allow')"
                >
                    {{ errors.first("total_day_allow") }}
                </div>
            </div>
        </div>

        <div class="col-md-12 tw-flex mb-3">
            <div class="col-md-6 tw-pr-2">
                <label class="form-col-label control-label">{{
                    $t("leaveRule.incrementDay")
                }}</label>
                <input
                    v-model.trim.number="model.increment_day"
                    type="number"
                    class="form-control"
                />
            </div>
            <div class="col-md-6 tw-pl-2">
                <label class="form-col-label control-label">{{
                    $t("leaveRule.incrementYear")
                }}</label>
                <input
                    v-model.trim.number="model.increment_interval_year"
                    type="number"
                    class="form-control"
                />
            </div>
        </div>

        <div class="col-md-12 tw-flex mb-3">
            <div class="col-md-6 tw-pr-2">
                <label class="form-col-label control-label">{{
                    $t("leaveRule.maxDayAllowCarryForward")
                }}</label>
                <input
                    v-model.trim.number="model.max_day_allow_carry_forward"
                    type="number"
                    class="form-control"
                />
            </div>
            <div class="col-md-6 tw-pl-2">
                <label class="form-col-label control-label">{{
                    $t("leaveRule.carryForwardClearanceIn")
                }}</label>
                <Select
                    v-model.number="model.carry_forward_clearance_in_month"
                >
                    <Option
                        v-for="(month, index) in months"
                        :key="index"
                        :value="month.value"
                        >{{ month.text }}
                    </Option>
                </Select>
            </div>
        </div>

        <div class="col-md-12 tw-flex mb-3">
            <div class="col-md-6 tw-pr-2">
                <label class="form-col-label control-label required">{{
                    $t("leaveRule.cycleStart")
                }}</label>
                <DatePicker
                    class="tw-w-full"
                    :value="model.cycle_start_ddmm"
                    @on-change="onChangeStart"
                    format="dd-MM"
                    type="date"
                    placeholder="DD-MM"
                    :class="{
                        'ivu-form-item-error': errors.has('cycle_start_ddmm')
                    }"
                />
                <div
                    class="ivu-form-item-error tw-text-red-600"
                    v-if="errors.has('cycle_start_ddmm')"
                >
                    {{ errors.first("cycle_start_ddmm") }}
                </div>
            </div>
            <div class="col-md-6 tw-pl-2">
                <label class="form-col-label control-label required">{{
                    $t("leaveRule.cycleStop")
                }}</label>
                <DatePicker
                    class="tw-w-full"
                    :value="model.cycle_stop_ddmm"
                    @on-change="onChangeStop"
                    format="dd-MM"
                    placeholder="DD-MM"
                    type="date"
                    :class="{
                        'ivu-form-item-error': errors.has('cycle_stop_ddmm')
                    }"
                />
                <div
                    class="ivu-form-item-error tw-text-red-600"
                    v-if="errors.has('cycle_stop_ddmm')"
                >
                    {{ errors.first("cycle_stop_ddmm") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <ts-checkbox v-model="model.allow_leave_when_no_balance">{{
                $t("leaveRule.allowLeaveWhenNoBalance")
            }}</ts-checkbox>
        </div>
        <div slot="footer">
            <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                <ts-button
                    @click.prevent="() => $emit('cancel')"
                    class="btn-gray"
                >
                    {{ $t("cancel") }}</ts-button
                >
                <ts-button
                    v-if="!isUpdate"
                    color="primary"
                    outline
                    :waiting="waiting_new"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onSaveAddNew"
                >
                    {{ $t("saveAddNew") }}</ts-button
                >
                <ts-button
                    color="primary"
                    v-if="!isUpdate"
                    :waiting="waiting"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onSave"
                >
                    {{ $t("save") }}</ts-button
                >
                <ts-button
                    v-if="isUpdate"
                    color="primary"
                    @click.prevent="onSave"
                    :waiting="waiting"
                >
                    {{ $t("update") }}</ts-button
                >
            </div>
        </div>
        <Spin size="large" fix v-if="loading"> </Spin>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapState } from "vuex";
import { isEmpty } from "lodash";

export default {
    name: "leave-ruleForm",
    i18n: {
        messages: {
            en: {
                months: {
                    January: "January",
                    February: "February",
                    March: "March",
                    April: "April",
                    May: "May",
                    June: "June",
                    July: "July",
                    August: "August",
                    September: "September",
                    October: "October",
                    November: "November",
                    December: "December"
                }
            },
            kh: {
                months: {
                    January: "មករា",
                    February: "កុម្ភះ",
                    March: "មិនា",
                    April: "មេសា",
                    May: "ឧសភា",
                    June: "មិថុនា",
                    July: "កក្កដា",
                    August: "សីហា",
                    September: "កញ្ញា",
                    October: "តុលា",
                    November: "វិច្ឆកា",
                    December: "ធ្នូ"
                }
            }
        }
    },
    data() {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            model: {
                emp_type_id: null,
                leave_type_id: null,
                total_day_allow: null,
                increment_day: null,
                increment_interval_year: null,
                max_day_allow_carry_forward: null,
                carry_forward_clearance_in_month: null,
                cycle_start_ddmm: null,
                cycle_stop_ddmm: null,
                allow_leave_when_no_balance: false
            }
        };
    },
    computed: {
        ...mapState("humanResource/leaveRule", [
            "edit_data",
            "employeeTypes",
            "leaveTypes"
        ]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        },
        months() {
            return [
                { text: this.$t("months.January"), value: 1 },
                { text: this.$t("months.February"), value: 2 },
                { text: this.$t("months.March"), value: 3 },
                { text: this.$t("months.April"), value: 4 },
                { text: this.$t("months.May"), value: 5 },
                { text: this.$t("months.June"), value: 6 },
                { text: this.$t("months.July"), value: 7 },
                { text: this.$t("months.August"), value: 8 },
                { text: this.$t("months.September"), value: 9 },
                { text: this.$t("months.October"), value: 10 },
                { text: this.$t("months.November"), value: 11 },
                { text: this.$t("months.December"), value: 12 }
            ];
        }
    },
    methods: {
        ...mapActions("humanResource/leaveRule", [
            "getEmployeeType",
            "getLeaveType"
        ]),
        async fetchResource() {
            this.loading = true;
            await this.getEmployeeType();
            await this.getLeaveType();
            this.loading = false;
        },
        onChangeStart(data) {
            this.model.cycle_start_ddmm = data;
        },
        onChangeStop(data) {
            this.model.cycle_stop_ddmm = data;
        },
        async onSaveAddNew() {
            this.errors = new Errors();
            this.waiting_new = true;
            this.$store
                .dispatch("humanResource/leaveRule/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("fetchData");
                    this.clearInput();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        async onSave() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("humanResource/leaveRule/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.messages
                    });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        setEditData() {
            this.fetchResource();
            if (!isEmpty(this.edit_data)) {
                this.model.emp_type_id = this.edit_data.emp_type_id;
                this.model.leave_type_id = this.edit_data.leave_type_id;
                this.model.total_day_allow = this.edit_data.total_day_allow;
                this.model.increment_day = this.edit_data.increment_day;
                this.model.max_day_allow_carry_forward = this.edit_data.max_day_allow_carry_forward;
                this.model.carry_forward_clearance_in_month = this.edit_data.carry_forward_clearance_in_month;
                this.model.cycle_start_ddmm = this.edit_data.cycle_start_ddmm;
                this.model.cycle_stop_ddmm = this.edit_data.cycle_stop_ddmm;
                this.model.allow_leave_when_no_balance = this.edit_data.allow_leave_when_no_balance;
            }
        },
        clearInput() {
            this.errors = new Errors();
            this.model.emp_type_id = null;
            this.model.leave_type_id = null;
            this.model.total_day_allow = null;
            this.model.increment_day = null;
            this.model.max_day_allow_carry_forward = null;
            this.model.carry_forward_clearance_in_month = null;
            this.model.cycle_start_ddmm = null;
            this.model.cycle_stop_ddmm = null;
            this.model.allow_leave_when_no_balance = false;
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "LEAVE RULE",
                desc: not.text
            });
        }
    }
};
</script>
